@import '../../vars.modules.scss';

.authWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color1;
  .authBox {
    background-color: #c9ada7ff;
    border-radius: 5px;
    height: 40%;
    min-height: 300px;
    width: 30%;
    min-width: 300px;
  }
}
