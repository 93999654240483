.wrapper {
  position: relative;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  margin: 20px;
}

.hand {
  border: 1px solid black;
}

.bottom, .top, .left, .right {
  position: absolute;
}

$height: 100px;

.bottom, .top {
  height: $height;;
  left: $height;
  width: calc(100% - 2 * #{$height});
}

.left, .right {
  height: calc(100% - 2 * #{$height});
  width: $height;
  top: $height;
}

.bottom {
  bottom: 0px;
}

.left {
  left: 0px;
}

.right {
  right: 0px;
}

.top {
  top: 0px;
}

.handPos {
  color: red;
}

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
}

.top, .bottom {
  .cardWrapper {
    flex-direction: row;
  }
}

.left, .right {
  .cardWrapper {
    flex-direction: column;
  }
}

.centerBoard {
  position: absolute;
  top: calc(#{$height} + 5px);
  left: calc(#{$height} + 5px);
  bottom: calc(#{$height} + 5px);
  right: calc(#{$height} + 5px);
  overflow: hidden;
  display: flex;
}

.bidcardWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameWrapper {
  display: flex;
  .contract {

  }
  .cardBoardWrapper {
    .cardsTitleWrapper {

    }
    .cardBoard {
      display: flex;
      flex-direction: row;
    }
  }
}
