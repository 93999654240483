@import '../vars.modules.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  .navWrapper {
    height: 100vh;
    order: 1;
    width: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: $color1;
    position: relative;
  }
  .contentWrapper {
    height: 100vh;
    flex-grow: 1;
    order: 2;
  }
  .lobbyPreview {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 60px;
    width: 100px;
  }
}
