@import '../../vars.modules.scss';

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
  .navbarItem {
    &.logIn{
      padding-right: 20px;
      a {
        border: 1px solid white;
        border-radius: 5px;
        display: inline-block;
        height: 30px;
        width: 70px;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
        color: white;
        text-decoration: none;
        &:hover {
          background: $fontColor;
          color: $color1;
        }
      }
    }
  }
}
