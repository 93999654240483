.biddingCard {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.biddingCardTitle {
  order: 1;
}

.biddingCardSelectorWrapper {
  order: 2;
  display: flex;
  flex-direction: row;
}

.biddingCardSuitWrapper {

}
