@import '../vars.modules.scss';

.preauth {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .navbarWrapper {
    background: $color1;
    color: $fontColor;
  }
  .pageWrapper {
    flex-grow: 1;
    display: flex;
    position: relative;
  }
}
