.board {
  order: 1;
  flex-grow: 1;
}

.extra {
  order: 2;
  flex-grow: 1;
  max-width: 250px;
  display: none;
}

.game {
  display: flex;
  height: 100%;
}
