.navUpper {
  .navbarItem {
    margin: 20px 0px;
    a {
      color: white;
    }
  }
}
.navLower {
  position: absolute;
  bottom: 0;
  width: 100%;
}
