@import './vars.modules.scss';

.loadingWrapper {
  background: $color1;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    color: $fontColor;
  }
}
