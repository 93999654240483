.wrapper {

}

.title {
  padding: 15px;
}

.form {

}

.email {
  padding: 30px 0px;
  width: 80%;
  text-align: center;
  margin-left: 10%;
  .emailInput {
    width: 100%;
    padding: 0;
  }
}

.password {
  width: 80%;
  padding: 30px 0px;
  text-align: center;
  margin-left: 10%;
  .passwordInput {
    width: 100%;
    padding: 0;
  }
}

.button {

}
